import React, { useEffect, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import TextStyle from '@tiptap/extension-text-style';
import FontSize from '../utils/FontSizeExtension';
import { Column, Row } from 'simple-flexbox';
import swal from 'sweetalert2';
import {validUrl} from './HelpfulFunction';
import HardBreak from '@tiptap/extension-hard-break';
import Image from '@tiptap/extension-image';
import UploadImage from '../utils/UploadImage';

const RichTextMarkdown = (props) => {
    const { placeholder, form={}, field={}, label, sublabel } = props;
    const fileInputRef = React.useRef(null);
    const [selectedFontSize, setSelectedFontSize] = useState('16px'); // default font size
    const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";

    // Editor setup
    const editor = useEditor({
        extensions: [
            Document,
            Paragraph,
            Text,
            Underline,
            Bold,
            Italic,
            Image,
            Placeholder.configure({
                placeholder: placeholder,
            }),
            Link.configure({
                HTMLAttributes: {
                    rel: 'noopener noreferrer nofollow',
                    target: '_blank',
                },
            }),
            TextStyle, // Necessary to apply the font size as a style
            FontSize, // Custom extension for font size with default 16px
            HardBreak
        ],
        editorProps: {
            attributes: {
                class: `form-control`,
            },
        },
        content: "", // Initially empty
        onUpdate: ({ editor }) => {
            const newValue = editor.getHTML();
            const isTextChanged = field.value !== newValue;
            if (isTextChanged) {
                form.setFieldValue(field.name, newValue);
                if (props.handleChange) {
                    props.handleChange(newValue);
                }
            }
        },
    });

    // Effect to set the content when the field value is loaded
    const getSelectedFontSize = () => {
        const { state } = editor.view;
        const { from, to } = state.selection;
        const defaultFontSize = '16px';
        const fontSizes = new Set();

        state.doc.nodesBetween(from, to, node => {
            if (node.isText) { // Check only text nodes
                const textSize = node.marks.find(mark => mark.type.name === 'textStyle' && mark.attrs.fontSize);
                if (textSize) {
                    fontSizes.add(textSize.attrs.fontSize);
                } else {
                    fontSizes.add(defaultFontSize); // Assume default size if no fontSize attribute
                }
            }
        });

        // If more than one unique font size is found, return an empty string to indicate "Mixed"
        if (fontSizes.size > 1) {
            return '';
        }

        // Return the single font size if only one is found, or the default size
        return fontSizes.size === 1 ? [...fontSizes][0] : defaultFontSize;
    };

    useEffect(() => {
        if (editor && field.value && editor.getHTML() !== field.value) {
            editor.commands.setContent(field.value); // Set the initial content
        }
    }, [field.value]);

    useEffect(() => {
        if (editor) {
            editor.on('selectionUpdate', () => {
                setSelectedFontSize(getSelectedFontSize());
            });
        }
    }, [editor]);

    const handleBold = () => {
        if (editor) {
            editor.chain().focus().toggleBold().run();
        }
    };

    const handleItalic = () => {
        if (editor) {
            editor.chain().focus().toggleItalic().run();
        }
    };

    const handleUnderline = () => {
        if (editor) {
            editor.chain().focus().toggleUnderline().run();
        }
    };

    const handleFontSizeChange = (event) => {
        const size = event.target.value;
        if (editor) {
            if (size) {
                editor.chain().focus().setFontSize(size).run();
            } else {
                editor.chain().focus().unsetFontSize().run();
            }
        }
    };

    const handleLink = async () => {
        const { value: url } = await swal.fire({
            title: 'Enter URL',
            input: 'text',
            inputPlaceholder: 'Enter your URL here',
            showCancelButton: true,
            confirmButtonText: 'Add Link',
            inputValidator: (value) => {
                if (!validUrl(value)) {
                    return 'Invalid URL. Please ensure the URL format is correct';
                }
            },
        });

        if (editor && url) {
            editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
        }
    };

    const handleUnlink = () => {
        if (editor) {
            editor.chain().focus().unsetLink().run();
        }
    };

    const handleImageUpload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleAddLineBreak = () => {
        if (editor) {
            editor.chain().focus().insertContent('<br />').run();
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const uploader = new UploadImage();
            const result = await uploader.upload_file(file);

            if (result.error) {
                swal.fire({
                    title: 'Error',
                    text: result.error,
                    icon: 'error',
                });
            } else if (result.imageUrl) {
                if (editor) {
                    editor.chain().focus().setImage({ src: result.imageUrl }).run();
                }
            }
        }
    };

    return (
        <Column style={{ width: '100%', maxWidth: 800, marginBottom: 10 }}>
            <Row>
                <label style={{ marginBottom: 0 }} htmlFor={field.id || field.name}>{label}</label>
            </Row>
            <Row>
                <span className="form-text">{sublabel}</span>
            </Row>
            <Row className="btn-group mb-3" role="group" style={{ maxWidth: 300 }}>
                <button type="button" className="btn btn-light" onClick={handleBold}><strong>B</strong></button>
                <button type="button" className="btn btn-light" onClick={handleItalic}><em>I</em></button>
                <button type="button" className="btn btn-light" onClick={handleUnderline}><u>U</u></button>
                {!isMlbApp &&
                    <>
                        <button type="button" className="btn btn-light" onClick={handleLink}><i className="fa fa-link"></i></button>
                        <button type="button" className="btn btn-light" onClick={handleUnlink}><i className="fa fa-unlink"></i></button>
                    </>
                }
                <button type="button" className="btn btn-light" onClick={handleAddLineBreak}><i className="fa fa-minus"></i></button>
                <button type="button" className="btn btn-light" onClick={handleImageUpload}>
                    <i className="fa fa-image"></i>
                </button>
                <select className="form-select" onChange={handleFontSizeChange} style={{ width: '100px' }} value={selectedFontSize}>
                    <option value="">Size</option>
                    <option value="12px">12px</option>
                    <option value="14px">14px</option>
                    <option value="16px">16px</option>
                    <option value="18px">18px</option>
                    <option value="20px">20px</option>
                    <option value="24px">24px</option>
                    <option value="28px">28px</option>
                    <option value="32px">32px</option>
                    <option value="48px">48px</option>
                    <option value="72px">72px</option>
                </select>
            </Row>
            <Row flexGrow={1}>
                <EditorContent style={{ width: '100%', fontFamily: 'Helevitica' }} editor={editor} />
            </Row>
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <Row>
                {form.touched && form.touched[field.name] && form.errors && form.errors[field.name] ? (
                    <div className="alert alert-danger mt-3">{form.errors[field.name]}</div>
                ) : null}
            </Row>
        </Column>
    );
};

export default RichTextMarkdown;
