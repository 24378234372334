import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { base, appDatabasePrimaryFunctions } from '../../base';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import {
    cleanCSVText,
    findFirstAndLastName,
    convertTimeStampToHumanReadable,
    cleanFirebaseText
} from '../utils/HelpfulFunction';
import DatePicker from '../utils/DatePicker';
import Papa from 'papaparse';

class AdminHome extends Component {
    constructor(props) {
        super(props);
        const todaysDateObject = new Date();
        todaysDateObject.setDate(todaysDateObject.getDate()-30);
        todaysDateObject.setHours(0,0,0,0); // Sets the time to start of the day (midnight)
        const endDateObject = new Date();
        endDateObject.setHours(23,59,59,999); // Sets the time to end of the day (11:59 pm)
        this.state = {
            games: 0,
            users: 0,
            itemsPerPage: 10,
            itemsToShow: 10,
            uniqueGamesUsers: 0,
            loading: false,
            players: 0,
            gameUsers: 0,
            prizesWon: 0,
            lastGame: {},
            uniqueGameUsers: false,
            gamesToShowList: [],
            searchStart: todaysDateObject,
            searchEnd: endDateObject
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [target.name]: value
        });
    }

    async componentDidMount(){
        this.getGames(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        this.getUsers(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        this.formQuestionsRef = base.bindToState('formQuestions', {
            context: this,
            asArray: true,
            state: 'formQuestions'
        })
        this.tenantVariablesRef = base.bindToState('tenantVariables', {
            context: this,
            state: 'tenantVariables'
        })
        let campaignRegistrants = {};
        let usersRegistrants = {};
        // let userGameHistory = {};
        // const currentGameId = await appDatabasePrimaryFunctions.ref('currentGame/id').once('value').then(function (snapshot) {return snapshot.val()})
        // for(let i = 0; i<2000; i++) {
        //     let user = {};
        //     let campaignRegistrantsObject = {};
        //     let email = "leejorgensen22+" + i + "@gmail.com";
        //     let uid = btoa(email);
        //     let timeStamp = new Date().getTime() + random(0, 2000);
        //     campaignRegistrantsObject['timeStamp'] = timeStamp;
        //     campaignRegistrantsObject['uid'] = uid;
        //     user['email'] = email;
        //     user['uid'] = uid;
        //     user['lastSignIn'] = timeStamp;
        //     // currentUserPicks[uid] = campaignRegistrantsObject;
        //     campaignRegistrants[uid] = campaignRegistrantsObject;
        //     usersRegistrants[uid] = user;
        //     if (i % 100 === 0) {
        //         await appDatabasePrimaryFunctions.ref('users').update(usersRegistrants)
        //         // await appDatabasePrimaryFunctions.ref(`userGameHistory/${uid}`).update({[currentGameId]: currentGameId})
        //         await appDatabasePrimaryFunctions.ref('campaignRegistrants').update(campaignRegistrants)
        //         usersRegistrants = {};
        //         campaignRegistrants = {};
        //     }
        //
        //     console.log(campaignRegistrantsObject)
        //     console.log(user)
        // }
            // const finsihed = await Promise.all([appDatabasePrimaryFunctions.ref('users').child(uid).set(user), appDatabasePrimaryFunctions.ref('campaignRegistrants').child(uid).set(campaignRegistrantsObject)])
            // const finsihed = await Promise.all([appDatabasePrimaryFunctions.ref('users').child(uid).set(user), appDatabasePrimaryFunctions.ref(`userGameHistory/${uid}/${currentGameId}`).set(campaignRegistrantsObject)])
            // console.log(finsihed)
        // await appDatabasePrimaryFunctions.ref('users').set(usersRegistrants)
        // await appDatabasePrimaryFunctions.ref('campaignRegistrants').set(campaignRegistrants)
    }

    async getGames(start = 0, end){
        const vm = this;
        let queryString = appDatabasePrimaryFunctions.ref('gamesList').orderByChild('timeStamp').startAt(start);
        let currentGame = null;
        let currentGameObject = await appDatabasePrimaryFunctions.ref('currentGame').once('value').then(function(snapshot){
            let gameTemp = snapshot.val();
            if(gameTemp && gameTemp.timeStamp > start){
                return gameTemp;
            } else {
                return null;
            }
        })
        if(end){
            queryString = queryString.endAt(end);
            if(currentGameObject && currentGameObject.timeStamp > end){
                currentGameObject = null;
            }
        }
        currentGame = currentGameObject;
        let currentGamePrizes = 0;
        let currentGameUsers = 0;
        let gamesCount = 0;
        if(currentGame){
            currentGamePrizes = currentGamePrizes + await appDatabasePrimaryFunctions.ref('emailsSent').once('value').then(function(snapshot){
                return snapshot.numChildren()
            })
            if(currentGame.participants === "allTime"){
                currentGameUsers = currentGameUsers +  await appDatabasePrimaryFunctions.ref("campaignRegistrants").once('value').then(function (snapshot){
                    return snapshot.numChildren()
                })
            } else {
                currentGameUsers = currentGameUsers +  await appDatabasePrimaryFunctions.ref("userPicks").orderByChild('timeStamp').once('value').then(function (snapshot){
                    return snapshot.numChildren()
                })
            }
            // const answersCount = await appDatabasePrimaryFunctions.ref("userAnswers").once('value').then(function(snapshot){
            //     return snapshot.numChildren()
            // })
            gamesCount++;
            currentGame.prizesWon = currentGamePrizes;
            currentGame.users = currentGameUsers;
            currentGame.currentGame = true;
        }
        queryString.once('value', function (snapshot) {
            let prizesCount = currentGamePrizes;
            let gameUsersCount = currentGameUsers;
            let gamesArray = [];
            let index = 0;
            snapshot.forEach(function (data) {
                index++;
                prizesCount += data.val().prizes || 0;
                gameUsersCount += data.val().users || 0;
                if(snapshot.numChildren() - vm.state.itemsPerPage < index){
                    gamesArray.unshift(data.val())
                }
            })
            if(currentGame){
                gamesArray.unshift(currentGame)
            }
            vm.setState({
                loading: false,
                games: gamesCount + snapshot.numChildren(),
                prizesWon: prizesCount,
                gameUsers: gameUsersCount,
                gamesToShowList: gamesArray
            })
        });
    }

    async getUsers(start=0, end, returnUsers=false){
        const vm = this;
        let queryString = appDatabasePrimaryFunctions.ref('users').orderByChild('signUpTime');
        if(start){
            queryString = queryString.startAt(start);
        }
        if(end){
            queryString = queryString.endAt(end);
        }
        if(returnUsers){
            return await queryString.once('value').then(function(snapshot) {
                if(snapshot.exists()){
                    return snapshot;
                }
            })
        } else {
            let optInCounts = 0;
            queryString.once('value', function(snapshot){
                snapshot.forEach(function (data){
                    const user = data.val();
                    if(user.optIn || user.optInTwo || user.optInThree || user.optInFour){
                        optInCounts++;
                    }
                })
                vm.setState({
                    loading: false,
                    users: snapshot.numChildren(),
                    optIns: optInCounts
                })
            });
        }
    }

    async getUserData(onlyOptIns) {
        this.setState({ loading: true });

        let searchStart = this.state.searchStart || 0;
        let searchEnd = this.state.searchEnd;
        if (typeof searchStart === "object") {
            searchStart = searchStart.getTime();
        }
        if (searchEnd && typeof searchEnd === "object") {
            searchEnd = searchEnd.getTime();
        }

        let csvMetadata = [
            [(process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID), "Game"],
            [""],
            ["Date Data Downloaded", new Date().toISOString().split("T")[0]],
            ["From", searchStart ? convertTimeStampToHumanReadable(searchStart) : ""],
            ["To", searchEnd ? convertTimeStampToHumanReadable(searchEnd) : ""],
            [""],
        ];

        let headers = [
            "Email", "First Name", "Last Name", "Other First Name", "Other Last Name", "Zip Code", "Street", "City", "State", "Address",
            "Birthday", "Phone Number", "Social Handle", "Fan Response Text", "Fan Response Text 2", "Short Text Response 1", "Short Text Response 2",
            "Custom Dropdown", "Opt-In", "Opt-In 2", "Opt-In 3", "Opt-In 4", "YesNoQuestion", "YesExplanation", "YesNoQuestion2", "YesExplanation2",
            "How They Heard", "User Image", "UTM Campaign", "UTM Medium", "UTM Source", "SRC Code", "Signed Up"
        ];

        let timeFrameUsers = await this.getUsers(searchStart, searchEnd, true);
        let userData = [];

        timeFrameUsers.forEach(snapshot => {
            let user = snapshot.val();
            if (onlyOptIns && !user.optIn && !user.optInTwo && !user.optInThree && !user.optInFour) {
                return;
            }
            let nameResponse = findFirstAndLastName(user.name);
            let otherNameResponse = findFirstAndLastName(user.otherName);
            userData.push([
                user.email || user.id || "", cleanCSVText(nameResponse[0]) || "", cleanCSVText(nameResponse[1]) || "", cleanCSVText(otherNameResponse[0]) || "", cleanCSVText(otherNameResponse[1]) || "",
                cleanCSVText(user.zipCode) || "", cleanCSVText(user.street) || "", cleanCSVText(user.city) || "", cleanCSVText(user.state) || "", cleanCSVText(user.address) || "",
                user.birthday || "", user.phoneNumber || "", user.socialHandle || "", cleanCSVText(user.fanResponseToCollectTextInput) || "", cleanCSVText(user.fanResponseToCollectTextInput2) || "",
                cleanCSVText(user.textInputOne) || "", cleanCSVText(user.textInputTwo) || "", cleanCSVText(user.customDropdownInput) || "", user.optIn || "", user.optInTwo || "",
                user.optInThree || "", user.optInFour || "", user.yesNoQuestions || "", cleanCSVText(user.yesExplanation) || "", user.yesNoQuestions2 || "", cleanCSVText(user.yesExplanation2) || "",
                user.howYouHeard || "", user.userImage || "", cleanCSVText(user.utmCampaign) || "", cleanCSVText(user.utmMedium) || "", cleanCSVText(user.utmSource) || "", cleanCSVText(user.srcCode) || "",
                user.signUpTime ? convertTimeStampToHumanReadable(user.signUpTime) : ""
            ]);
        });

        let csvData = csvMetadata.concat([headers]).concat(userData);
        let csvContent = Papa.unparse(csvData);
        let hiddenElement = document.createElement("a");
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        hiddenElement.target = "_blank";
        hiddenElement.download = `accounts_made_${searchStart}_${searchEnd}.csv`;
        hiddenElement.click();

        this.setState({ loading: false });
    }

    async downloadIntenseGameData(game) {
        this.setState({ loading: true });

        // Metadata for CSV
        let csvHeader = [
            [(process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID), 'Game'],
            [''],
            ['Date Data Downloaded', new Date().toISOString().split("T")[0]],
            ['Game Name', game.gameName],
            ['Start Time', game.scheduleInfo ? convertTimeStampToHumanReadable(game.scheduleInfo.performAt) : ""],
            ['End Time', game.scheduleInfo ? convertTimeStampToHumanReadable(game.scheduleInfo.endAt) : ""],
            [''],
        ];

        // Fetch user game data
        const gameAnswers = await appDatabasePrimaryFunctions.ref(`userAnswersHistory/${game.id}`).once('value').then(snapshot => snapshot.exists() ? snapshot.val() : []);

        let totalPrizes = Object.values(gameAnswers).filter(user => user.rewardSent).length;

        csvHeader.push(['Prizes Won', totalPrizes.toString()], ['']);

        // Column headers
        let baseHeaders = [
            'Email', 'First Name', 'Last Name', 'Other First Name', 'Other Last Name',
            'Zip Code', 'Street', 'City', 'State', 'Address', 'Birthday', 'Phone Number',
            'Social Handle', 'Fan Response Text', 'Fan Response Text 2', 'Short Text Response 1',
            'Short Text Response 2', 'Custom Dropdown', 'Opt-In', 'Opt-In 2', 'Opt-In 3',
            'Opt-In 4', 'YesNoQuestion', 'YesExplanation', 'YesNoQuestion2', 'YesExplanation2',
            'How They Heard', 'User Image', 'UTM Campaign', 'UTM Medium', 'UTM Source',
            'SRC Code', 'Reward', 'Code', 'Play Time', 'Redeemed'
        ];

        let formQuestionHeaders = [];
        let formQuestionIds = [];

        if (this.state.formQuestions) {
            this.state.formQuestions.forEach(formQuestion => {
                formQuestionHeaders.push(formQuestion.text || '');
                formQuestionIds.push(formQuestion.id);
            });
        }

        if (this.state.tenantVariables?.otherResponse) {
            formQuestionHeaders.push('Other');
        }

        let finalHeaders = [...baseHeaders, ...formQuestionHeaders];

        // Fetch users' additional data
        const usersData = await appDatabasePrimaryFunctions.ref('users').once('value').then(snapshot => snapshot.val() || {});

        // Prepare CSV data
        let csvData = [];
        Object.values(gameAnswers).forEach(userInfo => {
            const moreUserData = usersData[userInfo.uid] || {};
            let nameResponse = findFirstAndLastName(moreUserData.name);
            let otherNameResponse = findFirstAndLastName(moreUserData.otherName);

            let row = [
                moreUserData.email || moreUserData.id || '',
                nameResponse[0] || '',
                nameResponse[1] || '',
                otherNameResponse[0] || '',
                otherNameResponse[1] || '',
                moreUserData.zipCode || '',
                moreUserData.street || '',
                moreUserData.city || '',
                moreUserData.state || '',
                moreUserData.address || '',
                moreUserData.birthday || '',
                moreUserData.phoneNumber || '',
                moreUserData.socialHandle || '',
                moreUserData.fanResponseToCollectTextInput || '',
                moreUserData.fanResponseToCollectTextInput2 || '',
                moreUserData.textInputOne || '',
                moreUserData.textInputTwo || '',
                moreUserData.customDropdownInput || '',
                moreUserData.optIn || '',
                moreUserData.optInTwo || '',
                moreUserData.optInThree || '',
                moreUserData.optInFour || '',
                moreUserData.yesNoQuestions || '',
                moreUserData.yesExplanation || '',
                moreUserData.yesNoQuestions2 || '',
                moreUserData.yesExplanation2 || '',
                moreUserData.howYouHeard || '',
                moreUserData.userImage || '',
                moreUserData.utmCampaign || '',
                moreUserData.utmMedium || '',
                moreUserData.utmSource || '',
                moreUserData.srcCode || '',
                userInfo.rewardSent || '',
                userInfo.code || '',
                userInfo.timeStamp ? convertTimeStampToHumanReadable(userInfo.timeStamp) : '',
                userInfo.isRedeemed ? 'true' : 'false'
            ];

            // Add dynamic form question responses
            formQuestionIds.forEach(formQuestionId => {
                row.push(moreUserData.form_responses?.[formQuestionId] ?? userInfo[cleanFirebaseText(formQuestionId)] ?? '');
            });

            if (this.state.tenantVariables?.otherResponse) {
                row.push(moreUserData.otherInput || '');
            }

            csvData.push(row);
        });

        // Convert to CSV using PapaParse
        const csvContent = Papa.unparse({
            fields: finalHeaders,
            data: csvData
        });

        // Create and download CSV file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const hiddenElement = document.createElement('a');
        hiddenElement.href = url;
        hiddenElement.download = `gamedata_${game.gameName.replace(/[|&;$%@"<>()+,]/g, "").toLowerCase()}.csv`;
        document.body.appendChild(hiddenElement);
        hiddenElement.click();
        document.body.removeChild(hiddenElement);

        this.setState({ loading: false });
    }

    searchData(){
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        this.setState({
            loading: true,
            itemsPerPage: this.state.itemsPerPage
        }, ()=>{

        })
        this.getGames(searchStart,searchEnd)
        this.getUsers(searchStart,searchEnd)
    }

    onNextPage() {
        const vm = this;
        const newAmountToShow = this.state.itemsToShow + this.state.itemsPerPage;
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        let queryString = appDatabasePrimaryFunctions.ref('gamesList').orderByChild('timeStamp').limitToLast(newAmountToShow).startAt(searchStart);
        if (searchEnd) {
            queryString = queryString.endAt(searchEnd)
        }
        queryString.once('value', function (snapshot) {
            let gamesArray = [];
            snapshot.forEach(function (data) {
                gamesArray.unshift(data.val())
            })
            vm.setState({
                itemsToShow: newAmountToShow,
                gamesToShowList: gamesArray
            });
        })
    }

    // async loadTestGame(){
    //     // Define the updated questions and answers
    //     const numberOfIterations = 500;
    //
    //     for(let i = 0; i<numberOfIterations; i++){
    //         let user = {};
    //         let email = "leejorgensen22+"+i+"@gmail.com";
    //         let uid = btoa(email);
    //         let timeStamp = new Date().getTime();
    //         user['email'] = email;
    //         user['uid'] = uid;
    //         user['lastSignIn'] = timeStamp;
    //         user['signUpTime'] = timeStamp;
    //         // await Promise.all([appDatabasePrimaryFunctions.ref('users').child(uid).set(user)])
    //     }
    //
    //     for(let t = 0; t<numberOfIterations; t++) {
    //         let email = "leejorgensen22+" + t + "@gmail.com";
    //         let uid = btoa(email);
    //
    //         const payload = {uid: uid};
    //
    //         let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/sendImmediatePrizeWithOutAnswer`;
    //         if (process.env.NODE_ENV === "development") {
    //             url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/sendImmediatePrizeWithOutAnswer`;
    //         }
    //
    //         // Make a POST request to the Firebase function endpoint
    //         fetch(url, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(payload),
    //         })
    //             .then(response => response.json())
    //             .then(data => {
    //                 console.log(`Response for user ${t}:`, data);
    //             })
    //             .catch(error => {
    //                 console.error(`Error for user ${t}:`, error);
    //             });
    //     }
    // }

    render() {
        const totalUsers = this.state.users || 0;
        const optIns = this.state.optIns || 0;
        const gameUsers = this.state.gameUsers || 0;
        const prizesWon = this.state.prizesWon || 0;
        const fan_side_link = process.env.REACT_APP_FAN_LINK || "";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <div className="row" style={{width:'100%'}}>
                    <div className="col-md-11">
                        <p className="admin-header-text" style={{marginTop:'10px'}}>Game link: <span style={{fontSize:'20px'}}>{fan_side_link}</span></p>
                    </div>
                    <div className="col-md-1">
                        <p style={{color: "black"}}>
                            v{process.env.REACT_APP_VERSION}
                        </p>
                    </div>
                </div>
                {/*<div className="row">*/}
                {/*    <button onClick={() => this.loadTestGame()}>Load Test Immediate answer</button>*/}
                {/*</div>*/}
                <form className="form-horizontal" style={{marginLeft: 20, marginBottom:10}}>
                    <div className="row g-3 align-items-center">
                        <div className="col-auto">
                            <DatePicker
                                isClearable
                                selected={this.state.searchStart}
                                onChange={date => {
                                    if(date) date.setHours(0,0,0,0);
                                    this.setState({searchStart: date});
                                }}
                            />
                        </div>

                        <div className="col-auto">
                            <DatePicker
                                isClearable
                                selected={this.state.searchEnd}
                                onChange={date => {
                                    if(date) date.setHours(23,59,59,999);
                                    this.setState({searchEnd: date});
                                }}
                            />
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-primary btn-admin" type="button" onClick={()=>this.searchData()}>Search</button>
                        </div>
                    </div>
                </form>
                <div className="admin-grid-container four-columns">
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{totalUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Accounts Created</span>
                                    </div>
                                    {/*<div className="col-md-4" style={{fontSize: 40, alignSelf: "center"}}>*/}
                                    {/*    <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{color: "white", cursor: "pointer"}} onClick={() => this.getUserData()}/>*/}
                                    {/*</div>*/}
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{optIns}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Opt Ins</span>
                                    </div>
                                    <div className="col-md-4" style={{fontSize: 40, alignSelf: "center"}}>
                                        <i className="fa fa-arrow-circle-o-down" aria-hidden="true" style={{color: "white", cursor: "pointer"}} onClick={() => this.getUserData(true)}/>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{gameUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Game Users</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{prizesWon}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Prizes Won</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
                <div style={{margin:20}}>
                    {this.state.gamesToShowList && this.state.gamesToShowList.length > 0 &&
                    <>
                        {
                            this.state.gamesToShowList.map(function (item, index) {
                                return (
                                    <div key={index} className="card card-styles text-xs-center"
                                         style={{marginBottom: 10}}>
                                        <div className="card-body"
                                             style={{boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px"}}>
                                            <div className="row" style={{alignItems: 'center'}}>
                                                <div className="col-md-2">
                                                    <span style={{color: 'black', fontSize: 14}}>{convertTimeStampToHumanReadable(item.timeStamp)}</span>
                                                </div>
                                                <div className="col-md-3" style={{fontSize: 30}}>
                                                    {item.gameName}
                                                </div>
                                                <div className="col-md-3" style={{textAlign: 'right'}}>
                                                    <span style={{fontSize: 25}}>{item.users || 0}</span>
                                                    <span style={{color: 'black', fontSize: 15, marginRight: 15}}>Played</span>
                                                    <span style={{fontSize: 25}}>{item.prizes || item.prizesWon || 0}</span>
                                                    <span style={{color: 'black', fontSize: 15}}>Prizes Won</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="row" style={{fontSize: 20, justifyContent: 'center'}}>
                                                        {item.prizesWon !== null && !item.currentGame &&
                                                        <button onClick={() => this.downloadIntenseGameData(item)} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                            <span className="fa fa-arrow-circle-down"/> Download Game Data
                                                        </button>
                                                        }
                                                        {item.currentGame &&
                                                        <button onClick={() => window.location.href='/setupgame'} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                            Go To Current Game
                                                        </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }, this)
                        }
                        {this.state.games > this.state.gamesToShowList.length &&
                        <button className="btn btn-primary" onClick={()=>this.onNextPage()}>
                            More
                        </button>
                        }
                    </>
                    }
                </div>
            </div>
         </div>
        );
    }
}

export default AdminHome
